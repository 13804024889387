<template>
  <div class="home-detail">
    <div class="header" style="background: #fff">
      <Header activeMenuIndex="-1"></Header>
    </div>
    <div class="title-d">
      <span v-if="item.is_running">{{ $t("lang.opting") }} - </span>
      <span v-else>{{ $t("lang.market") }} - </span>
      <span style="color: #12022f">{{ item.title }}</span>
    </div>
    <div class="box-d">
      <div class="creating-n">
        <div class="back">
          <img class="product-img" :src="item.url"  />
          <!-- <video v-else :src="base" class="videoThumb" autoplay></video> -->
          <img src="../../assets/img/detail-wrap.svg" class="back-top" />
          <!-- <div class="back-zan">
            <img src="../../assets/img/dianzan.png" />
          </div> -->
          <el-tooltip  :content="$t('lang.CancelCollect')"  v-if="item.is_collect"  placement="top"   >
            <div   class="collect"  @click="collectThis(item)" :class="{ collected: item.is_collect }"  ></div
          ></el-tooltip>
          <el-tooltip :content="$t('lang.AddCollect')" v-else placement="top">
            <div  class="collect"   @click="collectThis(item)" :class="{ collected: item.is_collect }"  ></div ></el-tooltip>
          <span>#{{ item.id }}</span>
        </div>
      </div>
      <div class="creating-detail">
        <div class="endtime" v-if="item.is_running ==2 && item.is_on_selling == 1 && item.is_check == 5  ">
          <div class="title">{{ $t("lang.endTime") }}:</div>
          <div class="value">{{ endTime }}</div>
        </div>
        <div class="detail-metas">
          <div class="labels">
            <div class="label">{{ item.owner_name }}</div>
            <div class="label">{{ $t("lang.value") }}</div>
          </div>
          <div class="values">
            <div class="value">{{ item.title }}</div>
            <div class="value">
              <img :src="require('@/assets/img/nft.svg')" class="icon" />
              <div class="price">{{ item.amount || 0 }}</div>
              <!-- <div class="price" v-else-if="item.Price != ''">
                {{ item.Price }}
              </div>
              <div class="price" v-else></div> -->
            </div>
          </div>
        </div>

        <!-- 游戏 -->
        <div class="item" v-if="item.is_running ==2 && item.is_on_selling == 1 && item.is_check == 5  ">
          <div class="hd">{{ $t("lang.gameState") }}:</div>
          <div class="bd">
            <div class="state">
              <div class="item-smile" :class="{ fail: smile }"></div>
              <div class="item-progress">
                <div class="progress-text">
                  <div class="text-left">{{ item.approve_count }}</div>
                  <div class="win" v-if="current == 'win'"></div>
                  <div class="lose" v-if="current == 'lose'"></div>
                  <div class="draw" v-if="current == 'draw'"></div>
                  <div class="text-right">{{ item.defuse_count }}</div>
                </div>
                <div class="progress">
                  <div
                    class="bar"
                    v-bind:style="'width:' + item.Rate * 100 + '%'"
                  ></div>
                </div>
              </div>
              <div class="item-angry" :class="{ fail: angry }"></div>
            </div>
          </div>
        </div>

        <div class="creat-p" v-if="item.is_running ==2 && item.is_on_selling == 1 && item.is_check == 5  ">
          <span>{{ $t("lang.ticketPrice") }}:</span>
          <div class="con-i"><i></i>0.05</div>
        </div>
        <div class="creat-p" v-if="item.is_running ==2 && item.is_on_selling == 1 && item.is_check == 5  ">
          <span>{{ $t("lang.Allocation") }} :</span>
          <span class="icon">{{ $t("lang.Towinnners") }} : 30%</span>
          <span class="icon" style="background: #ddfff3"
            >{{ $t("lang.ToNFTLevelUp") }} : 60%</span
          >
          <!-- 提示 -->
          <!-- <el-tooltip class="item" effect="dark" placement="top">
            <div slot="content">
              {{ $t("lang.allocationInfoLine1") }}<br />{{   $t("lang.allocationInfoLine2")  }}
            </div>
            <span class="circle-help"></span>
          </el-tooltip> -->
        </div>
        <!-- 游戏时间等待 -->
        <div v-if="item.is_running ==2 && item.is_on_selling == 1 && item.is_check == 5  ">
          <div  class="actions"  v-if="item.isSame ==1 && !item.isEnd" >
            <el-button class="nft-btn" @click="openDialog(item)">{{  $t("lang.Join")   }}</el-button>
          </div>
          <!-- <div  class="actions"  v-if="item.isSame" >
            <el-button class="nft-btn btColor" @click="destroyed(item)">{{  $t("lang.Destroyed")   }}</el-button>
          </div> -->
         
          <!-- <div class="actions" >
            <el-button  class="nft-btn" @click="isEndRound(item)"  :loading="loginLoading" >{{ $t("lang.settle") }}</el-button >
          </div> -->
          <div class="actions" v-if="item.isSame ==0 && item.isEnd">
            <el-button  class="nft-btn" @click="isEndRound(item)" :loading="loginLoading"  :disabled="isSettled"  >{{ $t("lang.settle") }}</el-button >
          </div>
          <div  class="nei"  v-if="item.isEnd" >
            <img :src="require('@/assets/img/clock.svg')" />
            <span>{{ $t("lang.waiting") }}</span>
          </div>
        </div>
        
        <!-- 折线图 -->
        <div  class="recent-container"   >
          <div class="hd" v-if="item.is_running !=2 && item.is_on_selling == 1">{{ $t("lang.Recent") }}</div>
          <div class="bd" v-if="item.is_running !=2 && item.is_on_selling == 1">
            <el-tabs  class="custom-tabs"  :stretch="true"  @tab-click="tabsChange"  >
              <el-tab-pane :label="$t('lang.SalesHistory')">
                <line-chart height="260px" :data-option="option"></line-chart>
              </el-tab-pane>
              <el-tab-pane :label="$t('lang.HistoryOfParticipation')">
                <line-chart  height="260px"  :data-option="history"  v-if="isShowLine" ></line-chart>
              </el-tab-pane>
              <el-tab-pane :label="$t('lang.Description')">{{ item.description }}</el-tab-pane>
            </el-tabs>
          </div>
          <div class="mynft" v-if="item.is_on_selling == 2 && item.is_running ==1&&  item.is_check ==5">
            <div class="title">
              <div class="sellprice">{{ $t("lang.sellPrice") }}</div>
              <div class="eth">
                <div class="icon"></div>
                <div class="num">{{ item.price }}</div>
              </div>
            </div>
            <div class="act" v-if="item.isSame ==0">
              <div class="nft-btn" @click="cancelSell(item)">
                {{ $t("lang.cancel") }}
              </div>
            </div>
            <div class="act" v-else>
              <el-button class="nft-btn btn-block" @click="buyNFT(item)" >{{ $t("lang.buy") }} {{ item.price }} ETH {{ $t("lang.buyInfo") }}</el-button >
            </div>
            <!-- <div class="act" v-if="item.isSame">
              <div class="nft-btn btColor" @click="destroyed(item)">
                {{ $t("lang.Destroyed") }}
              </div>
            </div> -->
          </div>
        </div>

        <!-- 开始游戏 与出售 -->
        <!-- <div  class="actions right"  v-if="item.is_running ==1 && item.is_on_selling == 1 &&  item.is_check ==5 "   >
          <div class="nft-btn-default mr" style="background: #f2f2f2;color: black;">
            <i class="el-icon-loading" v-show="publishLoading"></i>
            {{ $t("lang.publish") }}
          </div>
          <div  class="nft-btn"   @click="OnSell"   v-if="item.next_sale_time  - new Date().getTime() < 0"  >
            {{ $t("lang.sell") }}
          </div>
          <div v-else class="nft-btn down">
            <countDate :remain-time="item.next_sale_time  - new Date().getTime()"   @sell="OnSell" />
          </div>
        </div> -->
        <div  class="actions right flex"  v-if="item.is_running ==1 && item.is_on_selling == 1 &&  item.is_check ==5 && item.isSame ==0"   >
          <div class="nft-btn-default mr" @click="isOnStartGame(item.id)">
            <i class="el-icon-loading" v-show="publishLoading"></i>
            {{ $t("lang.publish") }}
          </div>
          <div  class="nft-btn"   @click="OnSell"   v-if="item.next_sale_time  - new Date().getTime() < 0"  >
            {{ $t("lang.sell") }}
          </div>
          <div v-else class="nft-btn down">
            <countDate :remain-time="item.next_sale_time  - new Date().getTime()"   @sell="OnSell" />
          </div>
          <!-- <div class="img-box" @click="destroyed(item)">
             {{ $t("lang.Destroyed") }}
          </div> -->
        </div>
        <div class="card" v-if="item.is_running ==2 && item.is_on_selling == 1 && item.is_check == 5  ">
                <div class="hd">
                    <h2 class="title">{{ $t("lang.opting") }}</h2>
                </div>
                <div class="bd">
                    <div class="table-wrapper">
                       
                        <div   class="table-inner"    element-loading-spinner="el-icon-loading"  >
                            <el-table :data="dataSource">
                              <el-table-column :label="$t('lang.GameID')" prop="gameId"></el-table-column>
                              <el-table-column :label="$t('lang.GameTitle')" prop="gameTitle"> </el-table-column>
                              <el-table-column :label="$t('lang.amountTransaction')" prop="revoCion"></el-table-column>
                            </el-table>
                            <div class="pagination">
                                <el-pagination
                                    @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange"
                                    :current-page.sync="ipagination.pageNo"
                                    :page-size="ipagination.pageSize"
                                    layout="prev, pager, next, jumper"
                                    :total="ipagination.total">
                                </el-pagination>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
      </div>
    </div>
    <connectMetamask ref="showMetamask"></connectMetamask>
    <Footer></Footer>
    <cancel-dialog  ref="cancelDialog" @srcFail="srcFail"  @fail="openFail"  @offWeberr="offWeberr" ></cancel-dialog>
    <camp-dialog ref="campDialog" @srcFail="srcFail"  @fail="openFail" @offWeberr="offWeberr"  @reload="reload" ></camp-dialog>
    <saledetail-dialog  ref="saledetailDialog" @srcFail="srcFail" @fail="openFail" @offWeberr="offWeberr" ></saledetail-dialog>
    <fail-dialog ref="failDialog"></fail-dialog>
    <purchaseDialog ref="purchaseDialog"  @fail="openFail"  @offWeberr="offWeberr"  @reload="reload" ></purchaseDialog>
    <el-dialog  :visible="showPassword"  :title='$t("lang.PasswordVerification")'  :close-on-click-modal="false"  width="650px"  custom-class="nft-dialog"  @close="closeWord"  >
      <div class="nft-dialog-body">
        <div class="top-detail">
          <div class="right-info">
            <div class="row">
              <div>
                <span class="value">{{ $t("lang.value") }} </span>
                <img src="./../../assets/img/icon.png" alt="" />
                <span>{{ destroyedList.destory_rtv_amount }}</span>
              </div>
            </div>
          </div>
        </div>
        <el-input :placeholder="$t('lang.PleasePassword')" v-model="password" show-password></el-input>
        <div class="Confirm">
          <el-button @click="handleDestory()" :loading="showButtonLoading" round  >{{$t("lang.submit")}}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import Footer from "../../components/footer.vue";
import cancelDialog from "@/views/modules/cancelDialog";
import campDialog from "../pickMarket/modules/campDialog";
import LineChart from "@/views/modules/lineChart.vue";
import request from "../../services/request";
import ad from "../../utils/web3Helper";
import {BASE_V_API,countDownTime,BASE_GLOB_API} from "@/config.js"
import saledetailDialog from "../pickMarket/modules/saledetailDialog";
import moment from "moment";
import failDialog from "../modules/failDialog.vue";
import purchaseDialog from "../modules/purchaseDialog.vue";
import CountDate from "./../../components/CountDate.vue";
import { mapState } from "vuex";
import {axiosGet,axiosPost} from '@/api/api';
export default {
  name: "nftDetail",
  data() {
    return {
      url:{
        info:BASE_GLOB_API+'/user/info',
       },
      dataSource:[],
      ipagination:{
          pageNo:1,
          pageSize:10,
          total:0
      },
      showPassword:false,
      password:"",
      destroyedList:{},
      showButtonLoading:false,
      gameID:'',
      isClick:true,
      isGame:true,
      loginLoading:false,
      loading: false,
      publishLoading: false,
      isShowLine: false,
      item: {
       
      },
      base: "",
      activeName: "1",
      endTime: "",
      choose: 0,
      toWinners: 70,
      isVoted: false,
      userVoted: false,
      noButton: false,
      smile: false,
      angry: false,
      current: "",
      option: {
        title: {
          text: this.$t("lang.prices"),
          textStyle: {
            fontWeight: "normal",
            fontSize: 12,
            color: "#999",
          },
          top: "5px",
          left: "10px",
        },
        tooltip: {
          trigger: "axis",
          formatter: function (params, ticket, callback) {
            let res = "",
              date = "2021/" + params[0].axisValue;
            res =
              moment(date).format("MMMM DD, YYYY") +
              "<br />Price: " +
              params[0].value +
              " eth";
            setTimeout(function () {
              callback(ticket, res);
            }, 100);
            return "loading";
          },
        },
        grid: {
          top: "35px",
          bottom: "30px",
          right: "15px",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: "#aaa",
            },
          },
          data: ["7/24", "7/25", "7/26", "7/27", "7/28", "7/29", "7/30"],
        },
        yAxis: {
          axisLine: {
            show: true,
            lineStyle: {
              color: "#aaa",
            },
          },
          type: "value",
        },
        series: [
          {
            data: [234, 435, 354, 532, 123, 34, 43],
            type: "line",
            itemStyle: {
              color: "rgb(165,0,254)",
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(165,0,254,1)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(165,0,254,0)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
        ],
      },
      history: {
        title: {
          text: this.$t("lang.People"),
          textStyle: {
            fontWeight: "normal",
            fontSize: 12,
            color: "#999",
          },
          top: "5px",
          left: "10px",
        },
        tooltip: {
          trigger: "axis",
          formatter: function (params, ticket, callback) {
            let res = "",
              date = "2021/" + params[0].axisValue;
            res =
              moment(date).format("MMMM DD, YYYY") +
              "<br />People: " +
              params[0].value;
            setTimeout(function () {
              callback(ticket, res);
            }, 100);
            return "loading";
          },
        },
        grid: {
          top: "35px",
          bottom: "30px",
          right: "15px",
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLine: {
            lineStyle: {
              color: "#aaa",
            },
          },
          data: ["7/24", "7/25", "7/26", "7/27", "7/28", "7/29", "7/30"],
        },
        yAxis: {
          axisLine: {
            show: true,
            lineStyle: {
              color: "#aaa",
            },
          },
          type: "value",
        },
        series: [
          {
            data: [234, 435, 354, 532, 123, 34, 43],
            type: "line",
            itemStyle: {
              color: "rgb(165,0,254)",
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(165,0,254,1)", // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: "rgba(165,0,254,0)", // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          },
        ],
      },
    };
  },
  computed: {
    // ...mapState(["address"]),
    isSettled: function () {
      if (this.item.isEnd == true) {
        return false;
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.useInfo()
    this.loadData();
  },
  methods: {
    useInfo(){
          axiosGet(this.url.info).then(res=>{
              if(res.success){ 
                  window.localStorage.setItem("userinfo", JSON.stringify(res.result));
              }else{
                  this.$message.error(res.message);
              }
              
          })
        },

    handleDestory() {//mint
      if (this.password.length == 0) {
        this.showButtonLoading = false;
        this.$message.error(this.$t("lang.PleasePassword"));
        return;
      }
      var para = {
        nft_id: this.item.id,
        payPassword:this.password
      };

      let that = this;
      this.showButtonLoading = true;
      request.postDestory(para).then((res) => {
        if (res.code == 200) {
          that.showButtonLoading = false;
          that.closeWord()
          that.loadData();
          that.$message.success(res.message);
        }else{
          that.password=""
          that.$message.error(res.message);
          that.showButtonLoading = false;
        }
        
      }).catch((error) => {
        that.password=""
        that.showButtonLoading = false;
        that.$message.error(error.message);

       });
    },
    closeWord(){
      this.destroyedList ={}
      this.password =''
      this.showPassword =false
    },
    destroyed(val){
       this.destroyedList =val
       this.showPassword =true
    },
    srcFail(){
      this.loadData();
    },
    checkJoin() {//检测游戏当前状态
      var params = {
        player: this.address,
        token_id: this.$route.query.tokenID,
      };
      var that = this;
      request.checkJoin(params).then((response) => {
        let result = response.data;
        if (response.data.is_join) {
          that.choose = result.choose;
          that.current = result.current_win_or_lose;
        }
        if (that.choose == 1 && that.current == "win") {
          that.smile = false;
          that.angry = true;
        } else if (that.choose == 1 && that.current == "lose") {
          that.smile = true;
          that.angry = false;
        } else if (that.choose == 2 && that.current == "win") {
          that.smile = false;
          that.angry = true;
        } else if (that.choose == 2 && that.current == "lose") {
          that.smile = false;
          that.angry = true;
        }
        console.log("current: " + that.choose);
      });
    },
    reload(data) {
      if (data == true) {
        this.userVoted = data;
        console.log("userVoted: " + this.userVoted);
      }
      location.reload();
    },
    offWeberr() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      location.reload();
    },
    openFail(data) {
      this.weberr = false;
      console.log("data", data);
      this.$refs.failDialog.title = data.title;
      this.$refs.failDialog.descriptionText = data.desc;
      this.$refs.failDialog.openDialog();
    },
    initList(tokenID,pageNo, pageSize){
         console.log("1",tokenID)
          let params = {
              pageNo:pageNo,
              pageSize:pageSize,
              query:{
                gameId: tokenID
              }
          }
          request.serial(params).then((response) => {
            if (response.code == 200) {
                 let data = response.result;
                  this.dataSource = data.records;
                  this.ipagination.pageNo = Number(data.current);
                  this.ipagination.pageSize =Number(data.size);
                  this.ipagination.total =Number(data.total);
            }
       
          });
          
      },
      handleSizeChange(val) {
            //console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            //console.log(`当前页: ${val}`);
            this.initList(this.gameID,val, this.ipagination.pageSize);
        },
    loadData() {
      this.loading = true;
      var tokenID = "";
      this.gameID =''
      // var game_id = "";
      let userInfo = JSON.parse(localStorage.getItem("userinfo"));
      if (this.$route.query && this.$route.query.tokenID) {
        // tokenID = Number(this.$route.query.tokenID);
        this.gameID = Number(this.$route.query.tokenID);
        tokenID =this.gameID
        // game_id = Number(this.$route.query.id);
        this.initList(this.gameID,1,10)
        var para = { nft_id: tokenID };
        var that = this;
        var time = new Date().getTime(); //获取当前时间
        request.getNftInfo(para).then((response) => {
          that.item = response.data.res;
          console.log("22", response.data)
          // this.base = BASE_V_API + this.item.path;
          this.TokenID = response.data.res.id;
          if(that.item.next_sale_time){
          var date = new Date(that.item.next_sale_time)
          that.item.next_sale_time = date.getTime();    
          console.log("3333",that.item.next_sale_time)
          }else{
          that.item.next_sale_time = 0;
          }
          // this.item.owner = this.item.owner.toLowerCase(); //转小写
          var endTime = this.item.start_game *1000 + countDownTime * 1000; //游戏结束时间
          console.log("1",time)
          console.log("2",endTime)
          console.log("3",time > endTime)
          if (time > endTime) {
            that.item.isEnd = true;
            // if (that.address) {
            //   that.checkJoin();
            // }
          }else{
            that.item.isEnd = false;
          }
          // let arr = that.item.Path.split("/");
          // let fileName = arr[arr.length - 1];
          // let fileSplit = fileName.split(".");
          // let suffixType = fileSplit[fileSplit.length - 1];
          // if (suffixType == "mp4") {
          //   that.item.isVideo = true;
          // } else {
          //   that.item.isVideo = false;
          // }
          this.endTime = moment(endTime).format("yyyy-MM-DD HH:mm:ss"); //设置顶部结束时间
          // if (that.item.token_id && that.item.token_id.trim().length > 6) {
          //   that.item.token_id = that.item.token_id.substr(0, 4);
          // }
          var ac = parseInt(this.item.approve_count);
          var dc = parseInt(this.item.defuse_count);
          if (ac == 0 && dc == 0) {
            this.item.Rate = 0.5;
          } else {
            this.item.Rate = ac / (ac + dc);
          }
          //转换钱
          // this.item.price = ad.fromWei(this.item.price);
          // this.item.amount = ad.fromWei(this.item.amount);
          //Allocation
          if (this.item.amount < 1) {
            this.toWinners = 70;
          }
          if (this.item.amount << 10) {
            this.toWinners = 50;
          }
          if (this.item.amount > 1) {
            this.toWinners = 30;
          }
          //是否是自己的
          console.log("this.item.out_user_id",this.item.out_user_id)
          console.log("this.item.userInfo",userInfo)
          // console.log("this.item.userInfo",userInfo.id)
          // console.log("222",String(this.item.out_user_id) == String(userInfo.id))
          if(userInfo){
            if (String(this.item.out_user_id) == String(userInfo.id)) {
            this.item.isSame = 0;
            } else {
              this.item.isSame = 1;
            }
          }else{
            this.item.isSame =2
          }
          console.log("isSame", this.item.isSame)
          // if (this.item.owner.toLowerCase() == this.address) {
          //   if (this.item.owner) {
          //   that.item.isSame = true;
          // } else if (this.item.is_running) {
          //   ad.checkIsVotedOneGame(this.address, tokenID, function (result) {
          //     that.isVoted = result;
          //   });
          //   console.log("this.isVoted = " + that.isVoted);
          // }
          // console.log(that.item);
          //自己的nft中切换账号，不显示按钮
          // if ( !this.item.is_running ==1 &&  !this.item.is_on_selling ==1 && !this.item.isSame ) {
          //   this.noButton = true;
          // }
        // console.log( this.noButton,this.item.isSame)
          //输赢图片转换
          if (this.history.Result != "") {
            if (this.history.Result == 1) {
              this.smile = false;
              this.angry = true;
            } else if (this.history.Result == 2) {
              this.smile = true;
              this.angry = false;
            }
          }
          this.loading = false;

          // if (this.address) {
          //   this.loadSales();
          //   this.loadPeople();
          // }
        });
      }
    },
    isEndRound(item){
      if(this.isClick) {
        this.isClick = false;
        console.log('我被点击了');
        this.endRound(item);
        }else{
        console.log('请勿过快点击');
        }
    },
    // 游戏结算
    endRound(item) {
      this.loginLoading =true
      var that = this;
      var para = {
         game_id: item.game_id,
        };
        request.getGameEnd(para).then((response) => {
            if (response.code == 200) {
              that.loadData();
              that.$message.success(response.message);
              that.loginLoading =false
              setTimeout(()=>{ 
                that.isClick = true;
              },2000)
             }else {
              that.loginLoading =false
              that.$message.error(response.message);
              setTimeout(()=>{ 
                that.isClick = true;
              },2000)
            }
          }).catch(function (error) {
            that.loginLoading =false
            that.$message.error(error.message);
            setTimeout(()=>{ 
                that.isClick = true;
              },2000)
          });

      // ad.endRound(
      //   this.item.TokenID,
      //   function (result) {
      //     console.log("endRound.success", result);
      //     that.timer = setTimeout(that.offWeberr, 3 * 1000);
      //   },
      //   function (err) {
      //     console.log("endRound.err", err);
      //     var data = {
      //       title: that.$t("lang.failed"),
      //       desc: that.$t("lang.failedDesc"),
      //     };
      //     that.openFail(data);
      //   }
      // );
    },
    //购买
    buyNFT(item) {
      let token = localStorage.getItem("Token");
      // if (this.address == "") {
      //   this.$refs.showMetamask.show = true;
      // } else 
      if (!token) {
        sessionStorage.setItem("iSsignature", false);
        this.getSign();
        this.$router.push({name:'login'});
        // this.$router.push({name:'login',query:{redirect:window.vm.$router.currentRoute.fullPath}});
      } else {
        // this.$refs.purchaseDialog.TradeId = this.item.trade_id;
        // this.$refs.purchaseDialog.title = this.item.title;
        // this.$refs.purchaseDialog.price = this.item.price;
        // this.$refs.purchaseDialog.path = this.item.url;
        // this.$refs.purchaseDialog.isVideo = this.item.isVideo;
        this.$refs.purchaseDialog.openDialog(item);
      }
    },
    cancelSell(item) {//取消出售
      let token = localStorage.getItem("Token");
      // if (this.address == "") {
      //   this.$refs.showMetamask.show = true;
      // } else 
      if (!token) {
        sessionStorage.setItem("iSsignature", false);
        this.getSign();
        this.$router.push({name:'login'});
        // this.$router.push({name:'login',query:{redirect:window.vm.$router.currentRoute.fullPath}});
      } else {
        // this.$refs.cancelDialog.TradeId = this.item.TradeId;
        this.$refs.cancelDialog.openDialog(item);
      }
    },
    //收藏与取消
    collectThis: function (item) {
      //收藏和取消收藏
      let token = localStorage.getItem("Token");
      // if (this.address == "") {
      //   this.$refs.showMetamask.show = true;
      // } else 
      if (!token) {
        sessionStorage.setItem("iSsignature", false);
        this.getSign();
        this.$router.push({name:'login'});
        // this.$router.push({name:'login',query:{redirect:window.vm.$router.currentRoute.fullPath}});
      } else {
        var para = {
          nft_id: item.id,
        };
        if (item.is_collect == true) {
          request.delMyCollect(para).then((response) => {
            if (response.code == 200) {
              this.loadData();
              item.is_collect = !item.is_collect;
              this.$message.success(response.message);
            }else {
              this.$message.error(response.message);
            }
          });
        } else {
          request.addMyCollect(para).then((response) => {
            if (response.code == 200) {
              item.is_collect = !item.is_collect;
              this.loadData();
              this.$message.success(response.message);
            }else {
              this.$message.error(response.message);
            }
          });
        }
      }
    },
    openDialog(item) {
      let token = localStorage.getItem("Token");
      // if (this.address == "") {
      //   this.$refs.showMetamask.show = true;
      // } else
       if (!token) {
        sessionStorage.setItem("iSsignature", false);
        this.getSign();
        this.$router.push({name:'login'});
        // this.$router.push({name:'login',query:{redirect:window.vm.$router.currentRoute.fullPath}});
      } else {
        // this.$refs.campDialog.TokenID = this.item.TokenID;
        this.$refs.campDialog.openDialog(item);
      }
    },

    //出售
    OnSell() {
      let token = localStorage.getItem("Token");
      // if (this.address == "") {
      //   this.$refs.showMetamask.show = true;
      // } else  
      if (!token) {
        sessionStorage.setItem("iSsignature", false);
        this.getSign();
        this.$router.push({name:'login'});
        // this.$router.push({name:'login',query:{redirect:window.vm.$router.currentRoute.fullPath}});
      } else {
        // this.$refs.saledetailDialog.item = this.item;
        this.$refs.saledetailDialog.openDialog(this.item);
      }
    },
    //开始游戏
    isOnStartGame(item){
      if(this.isGame) {
        this.isGame = false;
        console.log('我被点击了');
        this.OnStartGame(item);
        }else{
        console.log('请勿过快点击');
        }
    },
    OnStartGame(tid) {
      let token = localStorage.getItem("Token");
      if (!token) {
        sessionStorage.setItem("iSsignature", false);
        this.getSign();
        this.$router.push({name:'login'});
        // this.$router.push({name:'login',query:{redirect:window.vm.$router.currentRoute.fullPath}});
      } else {
        if (this.publishLoading) {
          return;
        }
        this.publishLoading = true;
        var that = this;
        var para = {   
            nft_id: tid,
            period:countDownTime
          };
          request.startGame(para).then((res) => {
            if (res.code == "200") {
              that.publishLoading = false;
              that.loadData();
              setTimeout(()=>{ 
                that.isGame = true;
              },2000)
            }
          }).catch(function (error) {
            that.publishLoading = false;
            that.$message.error(error.message);
            setTimeout(()=>{ 
              that.isGame = true;
              },2000)
          });
        // ad.startGame(
        //   this.item.TokenID,
        //   function (res) {
        //     setTimeout(() => {
        //       location.reload();
        //     }, 1500);
        //   },
        //   function (err) {
        //     that.publishLoading = false;
        //     console.log(err);
        //     var data = {
        //       title: that.$t("lang.failed"),
        //       desc: that.$t("lang.failedDesc"),
        //     };
        //     that.openFail(data);
        //   }
        // );
      }
    },
    loadSales() {
      var para = {
        token_id: this.item.TokenID,
      };
      request.saleHistory(para).then((response) => {
        if (response.code == 200 && response.data != null) {
          this.option.series.data = response.data[0];
        }
      });
    },
    loadPeople() {
      var para = {
        token_id: this.item.TokenID,
      };
      request.players(para).then((response) => {
        if (response.code == 200 && response.data != null) {
          this.history.series.data = response.data[0];
        }
      });
    },
    tabsChange(value) {
      if (value.index === "1") {
        this.isShowLine = true;
      }
    },
  },
  components: {
    saledetailDialog,
    Header,
    Footer,
    cancelDialog,
    LineChart,
    campDialog,
    failDialog,
    purchaseDialog,
    CountDate,
  },
};
</script>

<style lang="less" scoped>
.home-detail {
  min-height: 100%;
  position: relative;
  .header {
    min-width: 1200px;
  }
  .title-d {
    padding-top: 34px;
    padding-bottom: 34px;
    width: 1200px;
    margin: auto;

    span {
      font-size: 14px;
      font-weight: 400;
      color: #594d6d;
    }
  }
  .box-d {
    width: 1200px;
    margin: auto;
    margin-bottom: 209px;
    display: flex;
    .creating-n {
      width: 490px;
      .back {
        width: 490px;
        height: 672px;
        //box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.22);
        border-radius: 28px;
        // border: 1px solid;
        //background-color: red;
        position: relative;
        padding: 12px;

        // img {
        // 	width: 100%;
        // 	height: 100%;
        // 	border-radius: 20px;
        // }
        .product-img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 25px;
        }
        .back-top {
          width: 100%;
          height: 100%;
          object-fit: contain;
          position: absolute;
        }
        .videoThumb {
          width: 520px;
          height: 640px;
        }
        span {
          position: absolute;
          bottom: 19px;
          right: 28px;
          font-size: 22px;
          font-weight: 400;
          color: #841ae9;
          z-index: 99;
        }
      }
      .back-zan {
        width: 54px;
        height: 54px;
        display: block;
        position: absolute;
        top: 33px;
        right: 33px;
        z-index: 99;
      }
      .back-top {
        width: 100%;
        height: 100%;
        // height: 384px;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 99;
      }
      .collect {
        background: #fff url("~@/assets/img/heart.svg") no-repeat center
          center/65% 65%;
        width: 54px;
        height: 54px;
        position: absolute;
        right: 30px;
        top: 30px;
        border-radius: 50%;
        transition: all 0.2s;
        cursor: pointer;
        z-index: 101;
        &.collected {
          background-image: url("~@/assets/img/heart-fill.svg");
        }
      }
    }
    .creating-detail {
      margin-left: 50px;
      flex-grow: 1;
      span {
        font-size: 12px;
        font-weight: 400;
        color: #594d6d;
      }
      .magin-20 {
        margin-top: 20px;
        display: block;
      }
      .con {
        font-size: 32px;
        font-weight: bold;
        color: #12022f;
        line-height: 32px;
        display: block;
        display: flex;
        margin-top: 5px;
        padding-bottom: 17px;
        border-bottom: 1px solid #eeeeee;

        i {
          display: block;
          width: 32px;
          height: 32px;
          background: url(../../assets/img/bianzu.png) no-repeat;
          background-size: 100% 100%;
          margin-right: 10px;
        }
      }
      h3 {
        font-size: 24px;
        margin-top: 3px;
        font-weight: 400;
        color: #12022f;
      }
      .foot {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 12px;
        div {
          margin: 0 8px;
          .progress-num {
            width: 500px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            span {
              font-size: 28px;
              font-weight: bold;
              color: #6a2ac8;
            }
            .cor {
              color: #fbad34;
            }
          }

          .progress {
            width: 500px;
            height: 10px;
            background: rgba(251, 173, 52, 1);
            text-align: center;
            color: #fff;

            .progressing {
              position: relative;
              float: left;
              margin: 0 auto;
              height: 10px;
              background: #6a2ac8;
            }
          }
        }
        img {
          width: 70px;
          height: 70px;
        }
      }
      .creat-p {
        display: flex;
        justify-content: start;
        margin-top: 37px;
        span {
          line-height: 24px;
        }
        .icon {
          padding: 2px 23px;
          background: #ffdddd;
          font-size: 14px;
          font-weight: 400;
          color: #12022f;
          margin-left: 23px;
          border-radius: 10em;
        }
        .time {
          font-size: 24px;
          font-weight: 400;
          color: #12022f;
          padding-left: 10px;
        }
        .con-i {
          font-size: 24px;
          font-weight: bold;
          color: #12022f;
          line-height: 28px;
          display: block;
          display: flex;

          i {
            display: block;
            width: 24px;
            height: 28px;
            background: url(../../assets/img/bianzu.png) no-repeat;
            background-size: 100% 100%;
            margin-right: 4px;
            margin-left: 5px;
          }
        }
        .circle-help {
          background: url("~@/assets/img/circle-info.svg") no-repeat center
            center/cover;
          width: 28px;
          height: 28px;
          margin-left: 20px;
        }
      }
      .butt {
        width: 254px;
        height: 56px;
        background: #ffdda9;
        border-radius: 28px;
        font-size: 16px;
        line-height: 56px;
        text-align: center;
        font-weight: 400;
        color: #12022f;
        margin-top: 37px;
      }
      .nei {
        width: 681px;
        height: 157px;
        background: #fdfbf2;
        border-radius: 8px;
        opacity: 0.85;
        border: 1px solid #ffecd1;
        margin-top: 24px;
        padding: 54px 29px;
        display: flex;
        img {
          width: 46px;
          height: 46px;
        }
        span {
          display: block;
          line-height: 46px;
          padding-left: 30px;
          font-size: 24px;
          font-weight: 600;
          color: #ebbc00;
        }
      }
      .nei-u {
        width: 681px;
        height: 157px;
        background: #fdfbf2;
        border-radius: 8px;
        opacity: 0.85;
        border: 1px solid #ffecd1;
        margin-top: 24px;
        line-height: 157px;
        display: flex;
        padding-left: 45px;
        span {
          font-size: 24px;
          font-weight: bold;
          color: #414141;
        }
        .jia {
          font-size: 64px;
          font-weight: bold;
          color: #ff2929;
          padding-left: 30px;
        }
      }

      .endtime {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: url("~@/assets/img/clock-blue.svg") no-repeat left
          center/24px 24px;
        padding-left: 34px;
        .title {
          margin-right: 20px;
        }
        .value {
          font-size: 30px;
          font-weight: 800;
        }
      }
      .detail-metas {
        background: #f3f3f3;
        border: #dcdcdc 1px solid;
        border-radius: 10px; //display:flex; justify-content: space-between;
        padding: 20px 30px;
        margin: 20px 0;
        .labels,
        .values {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .values {
          padding-top: 5px;
          font-weight: 800;
          font-size: 18px;
          .value {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .price {
              margin-left: 8px;
            }
          }
        }
        // .item-title {padding-bottom:5px;}
        // .item-value {
        //   display:flex; justify-content:flex-start; align-items:center; font-weight:800; font-size:18px;
        //   .icon {margin-right:10px;}
        // }
      }
      .mynft {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 25px 0;
        .title {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .sellprice {
            background: url("~@/assets/img/shopcart.svg") no-repeat left
              center/30px 28px;
            padding-left: 40px;
            font-size: 16px;
            line-height: 28px;
            margin-right: 50px;
          }
          .eth {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .icon {
              background: #e6e6e6 url("~@/assets/img/nft.svg") no-repeat center
                center/60% 60%;
              width: 42px;
              height: 42px;
              border-radius: 50%;
              margin-right: 15px;
            }
            .num {
              color: #841ae9;
              font-size: 42px;
            }
          }
        }
      }
      .item {
        .hd {
          padding-bottom: 5px;
        }
        .bd {
          .state {
            display: flex;
            justify-content: space-between;
            .item-smile {
              background: url("~@/assets/img/icon-sm.svg") no-repeat center
                center/cover;
              width: 68px;
              height: 68px;
              &.fail {
                background-image: url("~@/assets/img/icon-sm-gray.svg");
              }
            }
            .item-angry {
              background: url("~@/assets/img/icon-k.svg") no-repeat center
                center/cover;
              width: 68px;
              height: 68px;
              &.fail {
                background-image: url("~@/assets/img/icon-k-gray.svg");
              }
            }
            .item-progress {
              margin: 0 20px;
              flex-grow: 1;
              .progress-text {
                font-size: 28px;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                font-weight: 800;
                padding-bottom: 10px;
                .text-left {
                  color: #6a2ac8;
                }
                .text-right {
                  color: #fbad34;
                }
                .win {
                  background: url("~@/assets/img/win.png") no-repeat center
                    center/contain;
                  width: 61px;
                  height: 43px;
                }
                .lose {
                  background: url("~@/assets/img/lose.png") no-repeat center
                    center/contain;
                  width: 81px;
                  height: 43px;
                }
                .draw {
                  background: url("~@/assets/img/draw.png") no-repeat center
                    center/contain;
                  width: 92px;
                  height: 43px;
                }
              }
              .progress {
                background: #fbad34;
                height: 10px;
                .bar {
                  background: #6a2ac8;
                  height: 10px;
                  position: relative;
                  &:after {
                    content: "";
                    height: 18px;
                    background: #000;
                    width: 2px;
                    display: block;
                    position: absolute;
                    right: 0;
                    top: -4px;
                  }
                }
              }
            }
          }
        }
      }
      .actions {
        padding: 20px 0 0 0;
        &.right {
          text-align: right;
        }
        &.flex{
          display: flex;
        }
        .nft-btn,
        .nft-btn-default {
          width: 200px;
          &.is-disabled {
            opacity: 0.6;
            background: #ffdda9;
            border-color: #ffdda9;
            color: #999;
            cursor: not-allowed;
          }
          &.btColor{
            opacity: 0.6;
            background: #999;
            border-color: #999;
            // color: #999;
            cursor: not-allowed;
          }
        }
        .img-box {
          height: 55px;
          line-height: 55px;
          margin-left: 10px;
          width: 150px;
          background: #999;
          border-color: #999;
          border-radius: 10em;
          text-align:center;
        }
      
        .mr {
          margin-right: 20px;
        }
      }
      .card {
            padding: 30px 0;
            .hd {
                .title {}
            }
            .bd {
                .table-wrapper {
                    padding:10px 0;
                    .table-tools {
                        .table-tabs {
                            display:flex; justify-content: flex-start; align-items: center;
                            .tab-item {
                                line-height:1; padding:8px 14px; border-radius:4px; background:#f4f5f6; margin:0 10px 10px 0; cursor: pointer;
                                &.active, &:hover {
                                    background:#e7eafe; color:#4458fe;
                                }
                            }
                        }
                    }
                    .table-inner{
                        .pagination {
                            padding:15px 0; text-align:right;
                        }
                    }
                }
            }
        }
      .message-box {
        background: #fdfbf2;
        border: 1px solid #ffecd1;
        display: flex;
        opacity: 0.85;
        border-radius: 8px;
        min-height: 157px;
        align-items: center;
        box-sizing: border-box;
        padding: 30px;
        font-weight: 800;
        .title {
          font-size: 24px;
          margin-right: 15px;
        }
        .value {
          .nums {
            font-size: 36px;
            color: #f00;
            font-weight: 800;
            margin-right: 5px;
          }
          .unit {
            font-size: 24px;
            color: #f00;
            font-weight: 800;
          }
        }
      }
    }
  }
}
.nft-btn {
  height: 56px;
  border-radius: 10em;
  text-align: center;
  background: #ffdda9;
  border: #ffdda9 1px solid;
  color: #000;
  font-size: 16px;
  line-height: 26px;
  padding: 15px 60px;
  cursor: pointer;
  display: inline-block;
  &:hover {
    opacity: 0.6;
  }
  &.disabled,
  &.is-disabled,
  &.is-disabled:hover {
    opacity: 0.6;
    background: #ffdda9;
    color: #999;
    border-color: #ffdda9;
  }
  &.btColor{
    opacity: 0.6;
    background: #999;
    border-color: #999;
  }
  &.btn-block {
    display: block;
    width: 100%;
    box-sizing: border-box;
  }
}
.down {
  i {
    font-size: 20px;
    margin-right: 2px;
  }
  &:hover {
    opacity: 1;
  }
}
.nft-btn-default {
  height: 56px;
  border-radius: 10em;
  text-align: center;
  border: #dcdcdc 1px solid;
  color: #000;
  font-size: 16px;
  line-height: 26px;
  padding: 15px 60px;
  cursor: pointer;
  display: inline-block;
  &.is-disabled,
  &.is-disabled:hover {
    opacity: 0.6;
    color: #999;
    border-color: #dcdcdc;
  }
  &:hover {
    opacity: 0.6;
  }
  &.btColor{
    opacity: 0.6;
    color: #999;
    border-color: #999;
  }
}
.recent-container {
  .hd {
    padding: 20px 0 10px;
    font-size: 14px;
    line-height: 20px;
    color: #12022f;
  }
}
.custom-tabs {
  /deep/ .el-tabs__nav-wrap::after {
    background: transparent;
  }
  /deep/ .el-tabs__active-bar {
    background: #12022f;
  }
  /deep/ .el-tabs__item.is-active {
    color: #12022f;
  }
  /deep/ .el-tabs__item {
    color: #959595;
    font-weight: 400;
    padding: 10px 20px;
    font-size: 16px;
    box-sizing: content-box;
    &:hover {
      color: #12022f;
      opacity: 0.6;
    }
  }
  
}
.el-dialog__body {
    padding: 10px 40px 40px;
    .top-detail {
      display: flex;
      border-bottom: 1px solid #ddd;
      padding-bottom: 20px;
      .left-img {
        width: 53%;
        margin-right: 4%;
        text-align: center;
        img {
          width: 90%;
          border-radius: 10px;
        }
      }
      .right-info {
        width: 90%;
        .row {
          display: flex;
          justify-content: space-between;
          font-size: 16px;
          font-weight: 600;
          & > div {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 20px;
            .value {
              font-weight: 400;
              margin-right: 10px;
            }
            img {
              width: 24px;
              margin: 0 2px;
            }
          }
        }
      }
    }
    .Confirm {
      text-align: center;
      margin-top: 40px;
      .el-button {
        background: #ffdda9;
        border: none;
        &:hover {
          border: none;
          color: #333;
          background: #fad398;
        }
      }
    }
  }
</style>
